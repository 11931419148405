import { RichTextSliceProps } from '@ui/slice-machine/slices/rich-text-slice/RichText';
import dynamic from 'next/dynamic';

const RichTextSlice = dynamic<RichTextSliceProps>(() =>
  import('@ui/slice-machine/slices/rich-text-slice/RichText').then(
    (val) => val.RichText,
  ),
);

export default RichTextSlice;
